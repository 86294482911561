import React from "react";
import "./VerifyModal.css";
import { verifyUserDb } from "../../../utls/utls";

const VerifyModal = ({
  setVerifyUser,
  verifyUser,
  showModal,
  setShowModal,
}) => {
  const handleVerify = async (e, verifyUser) => {
    e.preventDefault();
    const res = await verifyUserDb(verifyUser);
    console.log(res);
    setShowModal(false);
  };
  return (
    <>
      <div
        className="verify_modal_container"
        style={showModal ? { display: "flex" } : { display: "none" }}
      >
        <div className="close_btn" onClick={() => setShowModal(false)}></div>
        <h1 className="modal_heading">Verify your email address</h1>
        <p className="modal_para">
          Please check your inbox for verification code sent to
        </p>
        <h3 className="modal_mail">{verifyUser.email}</h3>
        <div className="inputBOx">
          <input
            type="number"
            name="secretCode"
            placeholder="Enter Code"
            id="code"
            value={verifyUser.secretCode}
            onChange={(e) =>
              setVerifyUser({ ...verifyUser, secretCode: e.target.value })
            }
            className="email_text"
          />
        </div>
        <h3 className="modal_resend_code">
          Not received code?<span> Resend Code</span>
        </h3>
        <button
          className="modal_btn"
          onClick={(e) => handleVerify(e, verifyUser)}
        >
          Verify
        </button>
      </div>
    </>
  );
};

export default VerifyModal;
