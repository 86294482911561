import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import { InfinitySpin } from "react-loader-spinner";
import { getUserData, getUserDetail } from "../../utls/utls";

const Home = () => {
  const navigate = useNavigate();
  const current_url = window.location.pathname.split("/");
  const user_id = current_url[2];
  localStorage.setItem("id", user_id);
  // console.log(cookies.token)

  useEffect(() => {
    const checkIsRegistered = async (user_id) => {
      const thincificData = await getUserDetail(user_id);
      console.log(thincificData)
      window.sessionStorage.setItem("userThincific", JSON.stringify(thincificData));
      await getUserData(user_id)
        .then((res) => {
          // console.log(res);
          const userName = res?.data?.user[0]?.university;
          const userRole = res?.data?.user[0]?.role || "USER"
          // console.log(userRole)
          userName ? userRole==="Admin" ? navigate("/adminDashboard") : navigate("/dashboard") : navigate("/studentDetails");
        })
        .catch((error) => {
          console.log(error);
        });
    };
    checkIsRegistered(user_id);
  }, [navigate, user_id]);
  
  return (
    <>
      <div className="homeContainer">
        <InfinitySpin width="200" color="#4fa94d" />
      </div>
      <button></button>
    </>
  );
};

export default Home;
