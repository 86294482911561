import React, { useState, useEffect } from 'react'
import './DashboardTwo.css'
import MenuBar from '../MenuBar/MenuBar'
import {
  getUserData,
  getEnrollmentId,
  getCourseByCourseId,
  getAllReccomendedCourse
} from "../../utls/utls";
import { Link } from "react-router-dom";

import NewsCard from "../Shared/NewsCard/NewsCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import NewsData from "./NewsData";
import FooterTest from '../Shared/FooterTest/FooterTest'
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

const DashboardTwo = () => {
  const [course, setCourse] = useState([]);
  const [reccomendedCourse, setReccomendedCourse] = useState([]);
  const [activityCourse, setActivityCourse] = useState([]);
  const [userData, setUserData] = useState({
    first_name: "",
    name: "",
    user_image: "",
  });
  const user_id = localStorage.getItem("id");

  var settingsActivity = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    // arrows: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      }
    ],
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    // arrows: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 1,
        },
      },
    ],
  };

  var settingsCourse = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    // arrows: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 1,
        },
      },
    ],
  };


  const getCourses = async (user_id) => {
    const res = await getEnrollmentId(user_id);
    const courses = await getCourseByCourseId(res?.data?.items);
    console.log(courses)
    const data = courses?.filter((elm) => {
      return elm !== undefined && elm?.collection?.name !== 'Life Skills';
    });
    setCourse(data);
    const activitData = courses?.filter((elm) => {
      return elm?.collection?.name === 'Life Skills';
    });

    setActivityCourse(activitData);
    const userAcademicData = await getUserData(user_id);
    const { semester, university, branch } =
      userAcademicData.data.user[0];
    const recc = await getAllReccomendedCourse({
      semester,
      university,
      branch,
    });


    let reccData = recc?.data?.courseList;
    reccData?.push({ course_id: 2752498 });
    const reccCourses = await getCourseByCourseId(reccData);
    const recCourseData = reccCourses?.filter((elm) => {
      return elm !== undefined && courses?.filter((element) => {
        return elm?.course?.id !== element?.course?.id
      });
    });
    const result = recCourseData?.filter((course) => !courses?.some((recc) => course?.course?.id === recc?.course?.id))

    setReccomendedCourse(result);
  };

  const userDetails = async (user_id) => {
    try {
      // console.log(JSON.parse(window.sessionStorage.getItem("userThincific")))
      const res = JSON.parse(window.sessionStorage.getItem("userThincific"));
      setUserData({
        ...userData,
        first_name: res?.data?.first_name,
        name: res?.data?.full_name,
        user_image: res?.data?.avatar_url,
      });
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourses(user_id);
    userDetails(user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  // Carousel next arrow
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowRight className={className} onClick={onClick} style={{ ...style, color: "grey" }} />
    );
  }

  // Carousel prev arrow
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <MdKeyboardArrowLeft className={className} onClick={onClick} style={{ ...style, color: "grey" }} />
    );
  }

  return (
    <>
      <div className='dashboardTwo_container'>
        <MenuBar userData={userData} />
        <h1 style={{ fontSize: '3rem', margin: '1.2rem 0' }}>Hello, {userData?.first_name}!</h1>

        <div className='dashboard_hero'>
          {/* Dashboard left section */}
          <div className='dashboard_left'>

            {/* Your courses section */}
            <h1 style={{ textAlign: 'left', margin: '1rem' }}>Your Courses </h1>
            <div className='yourCourses_section'>
              <Slider {...settingsCourse} className='courseSlider'>
                {course.map((elm, index) => {
                  return <YourCourseCard data={elm} key={index} />;
                })}
              </Slider>
            </div>

            {/* Pathway section */}
            <h1 style={{ textAlign: 'left', marginLeft: '1rem', marginTop: '3rem' }}>Other Available Courses</h1>
            <div className='pathway_container'>
              {/* User's recommended course section */}
              <div className="course_content">
                {reccomendedCourse?.map((elm, index) => (
                  <CourseCard data={elm} key={index} />
                ))}
              </div>
            </div>
          </div>

          {/* Dashboard right section */}
          <div className='dashboard_right'>
            <h1 style={{ textAlign: 'left', margin: '1rem' }}>Life Skills</h1>
            {/* <div className='activity_section'> */}
            <Slider {...settingsActivity} className='activitySlider'>
              {activityCourse?.map((elm, index) => {
                return <ActivityCircle data={elm} key={index} />
              })}
            </Slider>
            {/* </div> */}

            {/* Industry news section */}
            {/* <div className='industry_news'> */}
            <h1 style={{ marginTop: '2rem' }}>Industry News</h1>
            {/* </div> */}
            <div className="news_container">
              <Slider {...settings} className="slide">
                {NewsData.map((elm, index) => {
                  return <NewsCard data={elm} key={index} />;
                })}
              </Slider>
            </div>
          </div>
        </div>
        <FooterTest />
      </div>
    </>
  )
}

export default DashboardTwo;

const YourCourseCard = ({ data }) => {
  const course_page_url = `https://learn.mindmatrix.io/courses/${data?.course?.slug}`;
  const percentage = Math.floor(data?.percentage_completed);

  const expiryDate = new Date(data?.expiry_date)
  const currentDate = new Date()

  const timeLeft = (expiryDate - currentDate);
  const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24))

  return (
    <>
      <Link
        to={course_page_url}
        style={{ textDecoration: "none" }}
      >
        <div className='yourCourseCard_Container'>
          <div
            style={{
              backgroundImage: `url(${data?.product?.card_image_url})`,
            }}
            className='yourCourseCard_Image'
          ></div>
          <h1 className="course_name">{data?.course?.name}</h1>
          <p className="course_category" style={{ fontSize: '1.3rem', margin: '0.3rem 0' }}>{data?.collection?.name}</p>
          <div className="course_card_footer">
            {/* {<h1 className="course_price">{(data?.product?.price == 0) ? 'Free' : data?.product?.price}</h1>} */}
            {(data?.percentage_completed) ? <h1 className='percentage_completed'>{percentage}% completed</h1> : null}
          </div>
          <p className="course_category" >{daysLeft ? <h1 style={{ fontSize: '1.8rem', fontFamily: 'Montserrat', textAlign: 'left', margin: '0.3rem 0' }}>Days left - {daysLeft} </h1> : null}</p>
        </div>
      </Link>
    </>
  )
}

const CourseCard = ({ data }) => {

  const course_page_url = `https://learn.mindmatrix.io/courses/${data?.course?.slug}`;
  const percentage = Math.floor(data?.percentage_completed);

  const expiryDate = new Date(data?.expiry_date)
  const currentDate = new Date()

  const timeLeft = (expiryDate - currentDate);
  const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24))

  return (
    <>
      <Link
        to={course_page_url}
        className="course_card courseCard_container"
        style={{ textDecoration: "none" }}
      >
        <div
          className="course_card_img"
          style={{
            backgroundImage: `url(${data?.product?.card_image_url})`,
          }}
        ></div>
        <h1 className="course_name">{data?.course?.name}</h1>
        <p className="course_category" style={{ fontSize: '1.3rem', margin: '0.3rem 0' }}>{data?.collection?.name}</p>
        <div className="course_card_footer">
          {/* {<h1 className="course_price">{(data?.product?.price == 0) ? 'Free' : data?.product?.price}</h1>} */}
          {(data?.percentage_completed) ? <h1 className='percentage_completed'>{percentage}% completed</h1> : null}
        </div>
        <p className="course_category" >{daysLeft ? <h1 style={{ fontSize: '1.8rem', fontFamily: 'Montserrat', textAlign: 'left', margin: '0.3rem 0' }}>Days left - {daysLeft} </h1> : null}</p>
      </Link>
    </>
  )
}

// const ActivityCard = ({ data }) => {
//   const course_page_url = `https://learn.mindmatrix.io/courses/${data?.course?.slug}`;
//   const percentage = Math.floor(data?.percentage_completed);

//   return (
//     <>
//       <Link
//         to={course_page_url}
//         className="course_card activityCard_container"
//         style={{ textDecoration: "none" }}
//       >
//         <div
//           className="course_card_img"
//           style={{
//             backgroundImage: `url(${data?.product?.card_image_url})`,
//             height: '7rem'
//           }}
//         ></div>
//         <h1 className="course_name">{data?.course?.name}</h1>
//         <p className="course_category">{data?.collection?.name}</p>
//         <div className="course_card_footer">
//           {/* {<h1 className="course_price">{(data?.product?.price == 0) ? 'Free' : data?.product?.price}</h1>} */}
//           {(data?.percentage_completed) ? <h1 className='percentage_completed'>{percentage}% completed</h1> : null}
//         </div>
//       </Link>
//     </>
//   )
// }

// const ActivityCardHexagone = ({ data }) => {
//   const course_page_url = `https://learn.mindmatrix.io/courses/${data?.course?.slug}`;
//   const percentage = Math.floor(data?.percentage_completed);

//   return (
//     <>
//       <div className="hexagone_container">
//         <div className='hexagone_container_img' style={{ backgroundImage: `url(${data?.product?.card_image_url})` }}></div>
//         <h1 className="course_name" style={{ textAlign: 'center' }}>{data?.course?.name}</h1>
//         <p className="course_category" style={{ textAlign: 'center' }}>{data?.collection?.name}</p>
//         <div className="course_card_footer">
//           {/* {<h1 className="course_price">{(data?.product?.price == 0) ? 'Free' : data?.product?.price}</h1>} */}
//           {(data?.percentage_completed) ? <h1 className='percentage_completed' style={{ textAlign: 'center', width: '100%' }}>{percentage}% completed</h1> : null}
//         </div>
//       </div>
//     </>
//   )
// }

// const ActivityCardCircle = ({ data }) => {
//   const course_page_url = `https://learn.mindmatrix.io/courses/${data?.course?.slug}`;
//   const percentage = Math.floor(data?.percentage_completed);

//   return (
//     <>
//       <div className="circle_container">
//         <div className='circle_container_img' style={{ backgroundImage: `url(${data?.product?.card_image_url})` }}></div>
//         <div>
//           <h1 className="course_name" style={{ textAlign: 'center' }}>{data?.course?.name}</h1>
//           <p className="course_category" style={{ textAlign: 'center' }}>{data?.collection?.name}</p>
//           <div className="course_card_footer">
//             {/* {<h1 className="course_price">{(data?.product?.price == 0) ? 'Free' : data?.product?.price}</h1>} */}
//             {(data?.percentage_completed) ? <h1 className='percentage_completed' style={{ textAlign: 'center', width: '100%' }}>{percentage}% completed</h1> : null}
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// const ActivityCardSemiCircle = ({ data }) => {
//   // const course_page_url = `https://learn.mindmatrix.io/courses/${data?.course?.slug}`;
//   const percentage = Math.floor(data?.percentage_completed);

//   return (
//     <>
//       <div className="semicircle_container">
//         <div className='semicircle_circle' style={{ backgroundImage: `url(${data?.product?.card_image_url})` }}></div>

//         <div className='semicircle_square'>
//           <h1 className="course_name" style={{ textAlign: 'center' }}>{data?.course?.name}</h1>
//           <p className="course_category" style={{ textAlign: 'center' }}>{data?.collection?.name}</p>
//           <div className="course_card_footer">
//             {/* {<h1 className="course_price">{(data?.product?.price == 0) ? 'Free' : data?.product?.price}</h1>} */}
//             {(data?.percentage_completed) ? <h1 className='percentage_completed' style={{ textAlign: 'center', width: '100%' }}>{percentage}% completed</h1> : null}
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

const ActivityCircle = ({ data }) => {
  const percentage = Math.floor(data?.percentage_completed);

  return (
    <>
      <div className='circle_container' style={{ backgroundImage: `url(${data?.product?.card_image_url})` }}>
        <div className='circle_overlay'>
          <h1 className="course_name" style={{ textAlign: 'center', color: 'white', marginTop: '6rem' }}>{data?.course?.name}</h1>
          <p className="course_category" style={{ textAlign: 'center', color: 'white' }}>{data?.collection?.name}</p>
          <div className="course_card_footer">
            {/* {<h1 className="course_price">{(data?.product?.price == 0) ? 'Free' : data?.product?.price}</h1>} */}
            {(data?.percentage_completed) ? <h1 className='percentage_completed' style={{ textAlign: 'center', width: '100%', color: 'white' }}>{percentage}% completed</h1> : null}
          </div>
        </div>
      </div>
    </>
  )
}

