import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import CourseCard from "../Shared/CourseCard/CourseCard";
import NewsCard from "../Shared/NewsCard/NewsCard";
import Footer from "../Shared/Footer/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import JoinSessionCard from "../Shared/joinSessionCard/joinSessionCard.js"
// import joinSessionCardData from "../Shared/joinSessionCard/joinSessionCardData";
import {
  getUserData,
  getEnrollmentId,
  getCourseByCourseId,
  getAllReccomendedCourse,
  generateUserTokenFromPensil,
} from "../../utls/utls";
import NewsData from "../LandingPage/NewsData";
import Navbar from "../Shared/Navbar/Navbar";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const user = useSelector(state => state)
  console.log('user', user)
  const [course, setCourse] = useState([]);
  const [reccomendedCourse, setReccomendedCourse] = useState([]);
  const [activityCourse, setActivityCourse] = useState([]);
  const [userData, setUserData] = useState({
    first_name: "",
    name: "",
    user_image: "",
  });
  const [college, setCollege] = useState("");
  const [pensilToken, setPensilToken] = useState();
  const [showName, setShowName] = useState(true);
  const user_id = localStorage.getItem("id");
  // console.log(user_id)
  // function askUser(e){
  //   e.preventDefault() 
  //   return 'test';
  
  // }
  // window.onbeforeunload = askUser;
  console.log(course)
  const getCourses = async (user_id) => {
    const res = await getEnrollmentId(user_id);
    // console.log("enrollment :", res);
    const courses = await getCourseByCourseId(res?.data?.items);
    const data = courses?.filter((elm) => {
      return elm !== undefined && elm?.collection?.name !== 'Activity';
    });
    setCourse(data);
    const activitData = courses?.filter((elm) => {
      return elm?.collection?.name === 'Activity';
    });
    setActivityCourse(activitData);
    const userAcademicData = await getUserData(user_id);
    // console.log(userAcademicData)
    const { semester, university, branch, college } =
      userAcademicData.data.user[0];
    setCollege(college?.name);
    const recc = await getAllReccomendedCourse({
      semester,
      university,
      branch,
    });
    // console.log(recc);
    

    let reccData = recc?.data?.courseList;
    reccData?.push({ course_id: 2752498 });
    const reccCourses = await getCourseByCourseId(reccData);
    const recCourseData = reccCourses?.filter((elm) => {
      return elm !== undefined && courses?.filter((element) => {
        return elm?.course?.id !== element?.course?.id
      });
    });
    // console.log(recCourseData)
    const result = recCourseData?.filter((course) => !courses?.some((recc) => course?.course?.id === recc?.course?.id ))
    
    setReccomendedCourse(result);
  };
  // console.log(reccomendedCourse);

  const userDetails = async (user_id) => {
    try {
      // console.log(JSON.parse(window.sessionStorage.getItem("userThincific")))
      const res = JSON.parse(window.sessionStorage.getItem("userThincific"));
      setUserData({
        ...userData,
        first_name: res?.data?.first_name,
        name: res?.data?.full_name,
        user_image: res?.data?.avatar_url,
      });
      // console.log(res);
      if (!pensilToken) {
        let data = {
          referenceIdInSource: res?.data?.email,
          createUser: true,
          name: res?.data?.first_name,
          description: `${res?.data?.first_name} is a ninja`,
        };
        // console.log(data)
        const resPensil = await generateUserTokenFromPensil(data);
        setPensilToken(resPensil?.data?.user?.token);
        localStorage.setItem("pensilToken", resPensil?.data?.user?.token);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const pensilUrl = `https://mindmatrix.pensil.in?token=${pensilToken}`;
  const hideVar = () => {
    setTimeout(() => {
      setShowName(false)
    }, 5000)
  }
  // console.log(pensilUrl);
  useEffect(() => {
    getCourses(user_id);
    userDetails(user_id);
    hideVar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  // const token = Cookies.get('token')
  // console.log(token)

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 2,
        },
      },
    ],
  };
// console.log(activityCourse.length)
  return (
    <>
      <div className="dashboard_container">
        <Navbar userData={userData} collegeName={college} />

        <div className="dashboard_hero_container">
          <div className="student_details">
            {showName ? (
              <div className="userName">
              <div className="hello">
                <h3>Hello, {userData?.first_name}!</h3>
              </div>
              <div className="welcome">
                <h5>Welcome Back</h5>
              </div>
            </div>
            ) : (<div></div>)}
            <div className="search">
              {/* <div className="search_place">
                <input type="search" placeholder="search for course name" />
                <div className="search_icon"></div>
              </div> */}
              {/* <Link to='/userProgress'>Your Progress</Link> */}
            </div>
          </div>

          {/* User's your courses section */}
          {course.length > 0 ? <h1 className="card_main_heading">your courses</h1> : null}
          <div className="course_content">
            {course?.map((elm, index) => (
              <CourseCard data={elm} key={index} />
            ))}
          </div>

          {/* User's recommended course section */}
          {reccomendedCourse && (
            <h1 className="card_main_heading">Recommended courses</h1>
          )}
          <div className="course_content">
            {reccomendedCourse?.map((elm, index) => (
              <CourseCard data={elm} key={index} />
            ))}
          </div>

          {/* Activity courses section */}
          {activityCourse?.length !== 0 ? <h1 className="card_main_heading">Activity courses</h1> : null}
          <div className="course_content">
            {activityCourse?.map((elm, index) => (
              <CourseCard data={elm} key={index} />
            ))}
          </div>

          <Link
          className="view_all_course"
          to="https://learn.mindmatrix.io/collections"
        >
          <button>View All Courses</button>
        </Link>

          {/* Activity page bannner */}
          {/* <div className="participate_head">
            <p className="participate_heading">PARTICIPATE AND GROW</p>
            <div className="main_img">
              <div className="card_content">
                <div className="card_left">
                  <h1 className="card_left_heading">PARTICIPATE AND GROW</h1>
                  <h1 className="card_left_para">
                    Engage in Interactive Activities to Enhance Your Skills and
                    Knowledge
                  </h1>
                </div>
                <Link to={pensilUrl} className="card_right_btn" target="_blank">
                  Explore Now
                </Link>
              </div>
            </div>
          </div> */}

          {/* <h1 className="card_main_heading">Google Certification</h1>
          <Link to="https://docs.google.com/forms/d/e/1FAIpQLSekJsspAQTmvevxCNV0zh-Hhq1akk0Nf4p7pjcvb1ZemEud1A/viewform?vc=0&c=0&w=1&flr=0" className="course_content" target="_blank">
            {joinSessionCardData?.map((elm, index) => (
              <JoinSessionCard data={elm} key={index} />
            ))}
          </Link> */}

        </div>

        <div className="industry">
          <h1 className="card_main_heading">INDUSTRY NEWS</h1>
        </div>
        <div className="dash_news_container">
          <Slider {...settings} className="slide">
            {NewsData.map((elm, index) => {
              return <NewsCard data={elm} key={index} />;
            })}
          </Slider>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;
